<template>
    <div class="main">
      <p class="tit" style="color: #D9EFFF;">检测数据</p>
      <div class="content">
        <div style="text-align: center;">
          <vue-ellipse-progress
            :progress="95"
            color="#4EA6FF"
            emptyColor="#415069"
            :size="90"
            fontSize="26px"
            lineMode="in-over"
            thickness="8"
            emptyThickness="4"
          >
            <span slot="legend-value">%</span>
          </vue-ellipse-progress>
          <p class="legend-name">检测报告覆盖率</p>
        </div>
        <div style="text-align: center;">
          <vue-ellipse-progress
            :progress="1"
            color="#14E89C"
            emptyColor="#415069"
            :size="90"
            fontSize="26px"
            lineMode="in-over"
            thickness="8"
            emptyThickness="4"
          >
            <span slot="legend-value">%</span>
          </vue-ellipse-progress>
          <p class="legend-name">器械误差率</p>
        </div>
        <div style="text-align: center;">
          <vue-ellipse-progress
            :progress="1"
            color="#FE7743"
            emptyColor="#415069"
            :size="90"
            fontSize="26px"
            lineMode="in-over"
            thickness="8"
            emptyThickness="4"
          >
            <span slot="legend-value">%</span>
          </vue-ellipse-progress>
          <p class="legend-name">器械掉线量</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HealthCoverageStatistics",
  
    data() {
      return {};
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 47px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
    }
    .content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      .legend-name {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
  </style>
  