<template>
  <div class="main">
    <p class="tit" style="color: #D9EFFF;">用户画像</p>
    <div class="echart-wrap gender-wrap mb20">
      <p class="g-tit mb20">性别分布</p>
      <div id="g-echarts" style="width: 100%; height: 130px" />
    </div>
    <div class="echart-wrap age-wrap">
      <p class="g-tit mb20">年龄分布</p>
      <div id="a-echarts" style="width: 100%; height: 230px" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HealthUserPortrait",
  data() {
    return {};
  },
  methods: {
    // 性别分布
    drawGenderChart(genderData) {
      const myChart = this.$echarts.init(document.getElementById("g-echarts"));
      const option = {
        legend: {
          top: "bottom",
          textStyle: { color: "#fff" },
          show: false, // 隐藏图例按钮
        },
        series: [
          {
            type: "pie",
            // center: ["50%", "40%"],
            center: ["50%", "50%"],
            data: genderData,
            avoidLabelOverlap: true, // 避免标签重叠
            label: {
          position: "outside", // 标签位置在饼图外
          formatter: " {b}: ({d}%)", // 标签格式
          // color: "#fff", // 标签文字颜色
          fontSize: 12, // 字体大小
        },
            itemStyle: {
              normal: {
                color: (colors) => {
                  const colorList = ["#228FFE", "#00D98B"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },

    // 年龄分布
    drawAgeChart(maleData, femaleData) {
      const myChart = this.$echarts.init(document.getElementById("a-echarts"));
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        legend: {
          data: ["男性", "女性"],
          textStyle: { color: "#fff" },
        },
        grid: {
          left: "10%",
          top: "10%",
          right: "10%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          data: ["60岁以下", "60-80岁", "80岁以上"],
          axisLine: { lineStyle: { color: "#fff" } },
          axisLabel: { color: "#fff" },
        },
        yAxis: {
          type: "value",
          axisLine: { lineStyle: { color: "#fff" } },
          axisLabel: { color: "#fff" },
        },
        series: [
          {
            name: "男性",
            type: "bar",
            data: maleData,
            itemStyle: { color: "#228FFE" },
          },
          {
            name: "女性",
            type: "bar",
            data: femaleData,
            itemStyle: { color: "#00D98B" },
          },
        ],
      };
      myChart.setOption(option);
    },
    fetchGenderData() {
      const vendorId = this.$route.query.vendorId;
    const apiUrl = vendorId
      ? `https://health.triplemaster.com/mgmt/statistics/bigscreen/gender?vendorId=${vendorId}`
      : "https://health.triplemaster.com/mgmt/statistics/bigscreen/gender";

    axios
      .get(apiUrl)
      .then((response) => {
        const propertyMap = response.data.data.propertyMap;
        const genderData = [
          { value: propertyMap["男性"], name: "男性" },
          { value: propertyMap["女性"], name: "女性" },
        ];
        this.drawGenderChart(genderData);
      })
      .catch((error) => {
        console.error("Error fetching gender data:", error);
      });
    },
    fetchAgeData() {
      const vendorId = this.$route.query.vendorId;
      const apiUrl = vendorId
        ? `https://health.triplemaster.com/mgmt/statistics/bigscreen/age?vendorId=${vendorId}`
        : "https://health.triplemaster.com/mgmt/statistics/bigscreen/age";

      axios
        .get(apiUrl)
        .then((response) => {
          const list = response.data.data.list;
          const ageCategories = ["60岁以下", "60岁-80岁", "80岁以上"];
          const maleData = ageCategories.map((ageGroup) => {
            const male = list.find(
              (item) => item.age === ageGroup && item.colorField === "男性"
            );
            return male ? male.percentage : 0;
          });
          const femaleData = ageCategories.map((ageGroup) => {
            const female = list.find(
              (item) => item.age === ageGroup && item.colorField === "女性"
            );
            return female ? female.percentage : 0;
          });
          this.drawAgeChart(maleData, femaleData);
        })
        .catch((error) => {
          console.error("Error fetching age data:", error);
        });
    },
  },
  mounted() {
    this.fetchGenderData();
    this.fetchAgeData();
    // 设置定时器，每60秒请求一次接口
    this.interval = setInterval(() => {
      this.fetchGenderData();
      this.fetchAgeData();
    }, 60000); // 60000 毫秒 = 1 分钟
  },
  destroyed() {
    // 组件销毁时清除定时器，避免内存泄漏
    clearInterval(this.interval);
  },
};
</script>

  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 30px;
    }
  
    .echart-wrap {
      position: relative; // 确保容器层级正确
      .g-tit {
        color: #fff;
        font-size: 16px;
      }
    
      // 设置 g-echarts 的高度和 z-index
      #g-echarts {
        width: 100%;
        height: 150px; // 可以适当增大高度
        z-index: 999; // 确保它位于其他元素之上
      }
    }
    
  }
  </style>
  