<template>
  <div class="main">
    <p class="tit mb20" style="color: #D0E5F5;">运动器械使用日活</p>
    <div id="echarts" style="width: 860px; height: 434px"></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HelloWord",
  data() {
    return {
      deviceData: [],
    };
  },
  watch: {
    // 监听路由参数 vendorId 的变化
    "$route.query.vendorId": {
      handler: "fetchData",
      immediate: true, // 初始化时立即调用
    },
  },
  methods: {
  async fetchData() {
    try {
      // 从 URL 查询参数中获取 vendorId
      const vendorId = this.$route.query.vendorId;

      // 构造请求 URL，根据 vendorId 是否存在动态拼接
      const url = vendorId
        ? `https://health.triplemaster.com/mgmt/statistics/bigscreen/device?vendorId=${vendorId}`
        : `https://health.triplemaster.com/mgmt/statistics/bigscreen/device`;

      // 发起网络请求
      const response = await axios.get(url);
      this.deviceData = response.data.data.list;
      this.updateChart();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  updateChart() {
    const myChart = this.$echarts.init(document.getElementById("echarts"));

    const titlename = this.deviceData.map(item => item.name);
    const data = this.deviceData.map(item => item.times);

    const myColor = ["#1089E7", "#F57474", "#56D0E3", "#F8B448", "#8B78F6"];
    
    const option = {
      title: {
        text: "器械使用累计（次）",
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        top: "10%",
        left: "16%",
        bottom: "10%",
      },
      xAxis: {
        show: false,
      },
      yAxis: [
        {
          show: true,
          data: titlename,
          inverse: true,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
            textStyle: {
              fontSize: 16,
            },
          },
        },
        {
          show: false,
          inverse: true,
          data: [],
          axisLabel: {
            textStyle: {
              fontSize: 20,
              color: "#fff",
            },
          },
        },
      ],
      series: [
        {
          name: "条",
          type: "bar",
          yAxisIndex: 0,
          data: data,
          barCategoryGap: 50,
          barWidth: 16,
          itemStyle: {
            normal: {
              barBorderRadius: 10,
              color: function (params) {
                var num = myColor.length;
                return myColor[params.dataIndex % num];
              },
            },
          },
          label: {
            normal: {
              show: true,
              position: "right",
              formatter: "{c}",
            },
          },
        },
        {
          name: "框",
          type: "bar",
          yAxisIndex: 1,
          barCategoryGap: 50,
          data: [100, 100, 100, 100, 100],
          barWidth: 15,
          itemStyle: {
            normal: {
              color: "none",
              borderWidth: 3,
              barBorderRadius: 15,
            },
          },
        },
      ],
    };
    myChart.setOption(option);
  },
},
  mounted() {
    this.fetchData();
     // 设置定时器，每60秒请求一次接口
     this.interval = setInterval(() => {
      this.fetchData();
    }, 62000); // 62000 毫秒 = 1 分钟
  },
  destroyed() {
    // 组件销毁时清除定时器，避免内存泄漏
    clearInterval(this.interval);
  },
};
</script>


  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 47px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
    }
  }
  </style>
  