<template>
  <div class="main">
    <p class="tit" style="color: #D9EFFF;">用户数据统计</p>
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div style="width: 500px; height: 230px">
        <dv-charts ref="chart1" :option="option1" />
      </div>
      <div style="width: 500px; height: 230px">
        <dv-charts :option="option2" />
      </div>
      <div style="width: 500px; height: 230px">
        <dv-charts :option="option3" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HealthUserData",

  data() {
    return {
      showChart1: false,
      option1: {
        title: {
          text: "进店会员人数",
          offset: [-130, -20],
          style: {
            fill: "#fff",
            fontSize: 17,
            fontWeight: "normal",
            textAlign: "center",
            textBaseline: "bottom",
          },
        },
        grid: {
          left: "10%",
          top: "19%",
          right: "10%",
          bottom: "10%",
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: ["11-11", "11-12", "11-13", "11-14"],
        },
        yAxis: {
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: "value",
        },
        series: [
          {
            data: [1, 3, 4,10],
            smooth: true,
            type: "line",
            lineStyle: {
              lineWidth: 3,
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#99CCFF",
                lineWidth: 1,
              },
            },
            lineArea: {
              show: true,
              gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
            },
          },
        ],
      },
      option2: {
        title: {
          text: "注册用户增长率",
          offset: [-130, -20],
          style: {
            fill: "#fff",
            fontSize: 17,
            fontWeight: "normal",
            textAlign: "center",
            textBaseline: "bottom",
          },
        },
        grid: {
          left: "10%",
          top: "19%",
          right: "10%",
          bottom: "10%",
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: ["11-07", "11-08", "11-09", "11-10", "11-11", "11-12", "11-13", "11-14"],
        },
        yAxis: {
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: "value",
        },
        series: [
          {
            data: [3, 3, 3, 3, 3, 3, 3, 7],
            smooth: true,
            type: "line",
            lineStyle: {
              color: "red",
              lineWidth: 3,
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#00D98B",
                lineWidth: 1,
              },
            },
            lineArea: {
              show: true,
              gradient: ["rgba(0, 217, 139, 0.6)", "rgba(0, 217, 139, 0)"],
            },
          },
        ],
      },
      option3: {
        title: {
          text: "付费会员增长率",
          offset: [-130, -20],
          style: {
            fill: "#fff",
            fontSize: 17,
            fontWeight: "normal",
            textAlign: "center",
            textBaseline: "bottom",
          },
        },
        grid: {
          left: "10%",
          top: "19%",
          right: "10%",
          bottom: "10%",
        },
        xAxis: {
          boundaryGap: false,
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: ["7月份", "8月份", "9月份", "10月份", "11月份"],
        },
        yAxis: {
          axisLine: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisTick: {
            style: {
              stroke: "#fff",
              lineWidth: 1,
            },
          },
          axisLabel: {
            style: {
              fill: "#fff",
              fontSize: 10,
              rotate: 0,
            },
          },
          data: "value",
        },
        series: [
          {
            data: [0, 50, 100, 150, 200],
            smooth: true,
            type: "line",
            lineStyle: {
              lineWidth: 3,
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#99CCFF",
                lineWidth: 1,
              },
            },
            lineArea: {
              show: true,
              gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
            },
          },
        ],
      },
    };
  },

  mounted() {
    // 在组件挂载后，发起网络请求
    this.fetchData();
    this.interval = setInterval(() => {
      this.fetchData();
    }, 64000); // 64000 毫秒 = 
  },
  destroyed() {
    // 组件销毁时清除定时器，避免内存泄漏
    clearInterval(this.interval);
  },
  watch: {
    // 监听路由参数 vendorId 的变化
    "$route.query.vendorId": {
      handler: "fetchData",
      immediate: true, // 初始化时立即调用
    },
  },
  methods: {
    fetchData() {
  const vendorId = this.$route.query.vendorId;
  const url = vendorId
    ? `https://health.triplemaster.com/mgmt/statistics/bigscreen/user/add?vendorId=${vendorId}`
    : `https://health.triplemaster.com/mgmt/statistics/bigscreen/user/add`;

  axios.get(url)
    .then(response => {
      const data = response.data.data.dateStat;
      console.log('Fetched data:', data);

      const dates = data.map(item => item.date);
      const amounts = data.map(item => item.amount === null ? 0 : item.amount);

      // 确保响应式更新
      this.$set(this.option2, 'xAxis', { ...this.option2.xAxis, data: dates });
      this.$set(this.option2.series[0], 'data', amounts);

      console.log(this.option2.series[0].data); // 确保数据已更新

      // 强制更新图表
      this.$nextTick(() => {
        // 强制刷新图表数据
        if (this.$refs.chart2) {
          this.$refs.chart2.setOption(this.option2);
        }
      });
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
},



  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  .tit {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .echart-wrap {
    .g-tit {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
