<template>
  <div class="main">
    <p class="tit" style="color: #D9EFFF;">用户统计</p>
    <div class="content">
      <div class="group-flex mb40">
        <div>
          <p class="data-tit">门店会员数（人）</p>
          <p class="data-val">{{ userData.registerAmount }}</p>
        </div>
        <div>
          <p class="data-tit">本周进店会员数（人）</p>
          <p class="data-val">{{ userData.serviceTimes }}</p>
        </div>
        <div>
          <p class="data-tit">本周服务人次（人）</p>
          <p class="data-val">{{ userData.serviceAmount }}</p>
        </div>
      </div>

      <div class="group-flex">
        <div class="d-flex algin-end">
          <div>
            <p class="mb26">同比上周</p>
            <div class="d-flex">
              <!-- 动态绑定箭头图片 -->
              <img
                :src="userData.registerAmountRatio >= 0 ? require('../../assets/imgs/arrow-up.png') : require('../../assets/imgs/arrow-down.png')"
                width="24px"
                height="24px"
                alt="箭头"
              />
              <!-- 动态绑定类名 -->
              <p :class="userData.registerAmountRatio >= 0 ? 'number-up' : 'number-down'" class="ml8">{{ userData.registerAmountRatio }}%</p>
            </div>
          </div>
          <div class="line-data-wrap">
            <div
              v-for="(v, i) in weekData1.data"
              :key="i"
              :style="{
                background: weekData1.up ? '#25D94B' : '#FC743E',
                height: v * 6 + 'px',
              }"
              class="line-item"
            ></div>
          </div>
        </div>

        <div class="d-flex algin-end">
          <div>
            <p class="mb26">同比上周</p>
            <div class="d-flex algin-center">
              <!-- 动态绑定箭头图片 -->
              <img
                :src="userData.serviceAmountRatio >= 0 ? require('../../assets/imgs/arrow-up.png') : require('../../assets/imgs/arrow-down.png')"
                width="24px"
                height="24px"
                alt="箭头"
              />
              <!-- 动态绑定类名 -->
              <p :class="userData.serviceAmountRatio >= 0 ? 'number-up' : 'number-down'" class="ml8">{{ userData.serviceAmountRatio }}%</p>
            </div>
          </div>
          <div class="line-data-wrap">
            <div
              v-for="(v, i) in weekData2.data"
              :key="i"
              :style="{
                background: weekData2.up ? '#25D94B' : '#FC743E',
                height: v * 6 + 'px',
              }"
              class="line-item"
            ></div>
          </div>
        </div>

        <div class="d-flex algin-end">
          <div>
            <p class="mb26">同比上周</p>
            <div class="d-flex algin-center">
              <!-- 动态绑定箭头图片 -->
              <img
                :src="userData.serviceTimesRation >= 0 ? require('../../assets/imgs/arrow-up.png') : require('../../assets/imgs/arrow-down.png')"
                width="24px"
                height="24px"
                alt="箭头"
              />
              <!-- 动态绑定类名 -->
              <p :class="userData.serviceTimesRation >= 0 ? 'number-up' : 'number-down'" class="ml8">{{ userData.serviceTimesRation }}%</p>
            </div>
          </div>
          <div class="line-data-wrap">
            <div
              v-for="(v, i) in weekData3.data"
              :key="i"
              :style="{
                background: weekData3.up ? '#25D94B' : '#FC743E',
                height: v * 6 + 'px',
              }"
              class="line-item"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


  
  <script>
  export default {
    name: "HealthServiceStatistics",
  
    data() {
      return {
        userData: {
        // 初始化数据，避免模板渲染时出错
        registerAmount: 0,
        serviceAmount: 0,
        serviceTimes: 0,
      },
        weekData1: {
          up: true,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
        weekData2: {
          up: true,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
        weekData3: {
          up: false,
          data: [5, 8, 10, 8, 10, 6, 8, 9, 10, 10, 10],
          max: 300,
        },
      };
    },
  
    mounted() {
    // 在组件挂载后，请求接口并更新数据
    this.fetchData();
    this.interval = setInterval(() => {
      this.fetchData();
    }, 64000); // 64000 毫秒 = 
  },
  destroyed() {
    // 组件销毁时清除定时器，避免内存泄漏
    clearInterval(this.interval);
  },
  watch: {
    // 监听路由参数 vendorId 的变化
    "$route.query.vendorId": {
      handler: "fetchData",
      immediate: true, // 初始化时立即调用
    },
  },
  methods: {
  async fetchData() {
    try {
      // 从 URL 查询参数中获取 vendorId（如果没有就保持 URL 原样）
      const vendorId = this.$route.query.vendorId;
      console.log("Vendor ID:", vendorId);
      // 构造请求 URL，根据 vendorId 是否存在动态拼接
      const url = vendorId
        ? `https://health.triplemaster.com/mgmt/statistics/bigscreen/general?vendorId=${vendorId}`
        : `https://health.triplemaster.com/mgmt/statistics/bigscreen/general`;

      // 发送 GET 请求获取数据
      const response = await this.$axios.get(url);

      // 假设接口返回的数据格式如下
      const data = response.data.data;

      // 更新 userData 的值
      this.userData = data;
        // 根据 registerAmountRatio 判断 weekData1.up
        if (this.userData.registerAmountRatio >= 0) {
        this.weekData1.up = true;
      } else {
        this.weekData1.up = false;
      }
        // 根据 registerAmountRatio 判断 weekData1.up
        if (this.userData.serviceAmountRatio >= 0) {
        this.weekData2.up = true;
      } else {
        this.weekData2.up = false;
      }
        // 根据 registerAmountRatio 判断 weekData1.up
        if (this.userData.serviceTimesRation >= 0) {
        this.weekData3.up = true;
      } else {
        this.weekData3.up = false;
      }
    } catch (error) {
      console.error("接口请求失败", error);
    }
  },
},
  };
  </script>
  
  <style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    padding: 30px 47px;
  
    .tit {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 40px;
      text-align: center;
    }
  
    .group-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        flex: 1;
      }
      .data-tit {
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 36px;
      }
  
      .data-val {
        font-size: 50px;
        color: #ffffff;
        // font-weight: bold;
        line-height: 1;
      }
      .number-up {
        color: #25d94b;
        font-size: 24px;
        font-weight: bold;
        height: 24px;
        line-height: 24px;
      }
      .number-down {
        color: #fc743e;
        font-size: 24px;
        font-weight: bold;
        height: 24px;
        line-height: 24px;
      }
      .line-data-wrap {
        margin-left: 20px;
        height: 60px;
        display: flex;
        align-items: end;
        .line-item {
          width: 8px;
          height: 100%;
          margin-right: 5px;
          border-radius: 50px;
        }
      }
    }
  }
  </style>
  